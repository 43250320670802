//@ts-check
import React, { useEffect, useLayoutEffect, useReducer, useRef, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { TransformWrapper, TransformComponent, useControls } from "react-zoom-pan-pinch";

import { idFix } from './id-fix';
import { env } from './env';
import NewApp from './new-app';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />

  </React.StrictMode>
);



function App() {
  if(env.showNewEditor) {
    return <NewApp/>
  } else {
    return <OldApp/>
  }
}
function OldApp() {
  var [currentClassId, set_currentClassId] = useState('')
  var svg = useSVG()
  var ids = useRef([])
  var [item, set_item] = useState({})
  var [showImage, set_showImage] = useState('')
  var [showLong, set_showLong] = useState('')
  useEffect(() => {
    (async () => {
      var name=require('querystring').parse(window.location.search.slice(1)).name
      var idsText = await (await fetch('/all.txt')).text()
      console.log({ idsText })
      var _ids = JSON.parse('[' + idsText.trim().split('\n').join(',') + ']')
      ids.current = _ids
    })()
  }, [])
  useEffect(() => {
    (async () => {
      if (!currentClassId) return
      // var i=await loadImages(currentClassId.replace(/.*?\-/,''))
      console.log({ currentClassId })
      console.log(ids.current.length)
      var f = ids.current.find(x => {
        console.log({ a: x.item, b: currentClassId, c: x.item == currentClassId })
        return x.item == currentClassId
      })
      if(!f){
        f={
          short:currentClassId,
          images:[]
        }
      }
      console.log(f)
      set_item(f)
    })();
  }, [currentClassId, ids])

  if (!svg.content) return <div>Loading...</div>

  var top = window.innerHeight / 2 - svg.height / 2 - 50
  var left = window.innerWidth / 2 - svg.width / 2
  return <div className="above-all">
    {showImage && <div
      onClick={() => {
        set_showImage('')
      }}
      style={{
        background: 'url(' + showImage + ')'
      }}
      className='big-image'></div>}
    {showLong && <div
      onClick={() => {
        set_showLong('')
      }}
      style={{
      }}
      className='big-text'>
        <div>
        <Bolder text={showLong}/>
        </div>
        </div>}
    <div className="float-up">
      תוכנית שתילה
    </div>
    <div className="l1">
      <TransformWrapper
        maxScale={20}
        limitToBounds={false}
        // centerOnInit={true}
        initialPositionY={top}
      >
        <InsideTransform svg={svg} set_currentClassId={set_currentClassId} />
      </TransformWrapper>
    </div>
    <div className="float">
      <div style={{ flexGrow: 1 }}>
        {!item?.itemShort && " "}
        {item?.itemShort}
      </div>
      <div>
        {item?.short &&
        <>
        <Bolder text={item?.short}/>
        {item.long &&<span onClick={()=>{
          set_showLong(item?.long)
        }} style={{color:'skyblue'}}>&nbsp;קרא עוד...</span>}
        </>
        }
      </div>
      <div>
        {item?.images?.slice(0, 5).map((x,index) =>
          <div
            key={index}
            className='image'
            onClick={() => {
              //#ts-ignore
              // window.open(x.thumb,'_new')
              set_showImage(x.thumb)
            }} style={{
              marginLeft: 20,
              //@ts-ignore
              width: x.thumbWidth / 10,
              verticalAlign: 'middle',
              display: 'inline-block',
              //@ts-ignore
              height: x.thumbHeighth / 10,
              //@ts-ignore
              background: 'url(' + x.thumb + ')',
            }}></div>)}
      </div>
    </div>
  </div>
}

function InsideTransform({ svg, set_currentClassId }) {
  var controls = useControls()
  return <div style={{ position: 'relative' }}>
    <div className="buttons">
      <button onClick={() => controls.zoomIn()}>+</button>
      &nbsp;
      <button onClick={() => controls.zoomOut()}>-</button>
    </div>
    <TransformComponent>
      <div style={{ width: '100vw', textAlign: 'center', height: '100vh' }}>
        <TheSVG onClick={set_currentClassId} svg={svg} />
      </div>
    </TransformComponent>
  </div>
}

function useSVG() {
  var [svg, set_svg] = useState({ content: '', width: 0, height: 0 })
  useEffect(() => {
    (async () => {

      var name=require('querystring').parse(window.location.search.slice(1)).name
      var org = await (await fetch('/' + name + '.svg')).text()
      var m = org.match(/width="(?<width>[0-9]+)" height="(?<height>[0-9]+)"/)?.groups
      if (!m) return
      var width = parseInt(m.width)
      var height = parseInt(m.height)
      var ratio = width / height

      if (width > window.innerWidth) {
        width = window.innerWidth
        height = width / ratio
      }
      if (height > window.innerHeight) {
        height = window.innerHeight
        width = height * ratio
      }
      org = org.replace(/width="([0-9]+)"/, 'width="' + width + '"')
      org = org.replace(/height="([0-9]+)"/, 'height="' + height + '"')
      // var fixed = org
      org = idFix(org)
      // for (var i = 0; i <= 26; i++) {
      //   var reg = new RegExp('&#215;&#' + (144 + i) + ';', 'g')
      //   var found = fixed.match(reg)
      //   fixed = fixed.replace(reg, String.fromCharCode(1488 + i))
      // }
      set_svg({
        content: org,
        width,
        height
      })
    })();
  }, [])
  return svg
}
function TheSVG({ onClick, svg }) {
  var x = useRef(null)
  var content = svg.content
  useLayoutEffect(() => {
    if (!x.current) return
    function clickSvgID(e) {
      console.log('clicked')
      findInnerTarget(e.target)
    }
    function findInnerTarget(e) {
      var y = find(e)
      if (y) {
        document.querySelectorAll('.clicked').forEach(item => {
          item.setAttribute('class', "")
        })
        y.setAttribute('class', "clicked")
        var name = y.id
        name = name.replace(/_.*/g, '')
        onClick(name)

      }
    }
    function find(e) {
      if (!e) return
      if (e.id.match(/[אבגדהוזחטיכלמנסעפצקרשת]/) && e.id.match(/\-/)) return e
      if (e == e.parentElement) return
      return find(e.parentElement)
    }
    //@ts-ignore
    x.current.addEventListener('click', clickSvgID)
    return () => {
      //@ts-ignore
      x.current.removeEventListener('click', clickSvgID)
    }
  }, [])

  return <div className="svgUp"><div ref={x} dangerouslySetInnerHTML={{ __html: content }}>
  </div></div>
}

function Bolder({text=''}) {
  var x=text.split('**')
  var e=[]
  var inBold=false
  var index=0
  for(var i of x){
    index++
    if(!inBold){
      e.push(<span key={index}>{i}</span>)
    } else {
      e.push(<b key={index}>{i}</b>)
    }
    inBold=!inBold
  }
  return <> {e} </>
}