import React, { useState, useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';

const DraggableClone = ({ hideOnDrag=false, children, onRelease }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [clonePosition, setClonePosition] = useState({ x: 0, y: 0 });
  const [mouseOffset, setMouseOffset] = useState({ x: 0, y: 0 });
  const originalRef = useRef(null);
  const cloneRef = useRef(null);

  const handleMouseDown = (e) => {
    const rect = originalRef.current.getBoundingClientRect();
    setMouseOffset({ x: e.clientX - rect.left, y: e.clientY - rect.top });
    setClonePosition({ x: rect.left, y: rect.top });
    setIsDragging(true);
    e.stopPropagation();
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    setClonePosition({ x: e.clientX - mouseOffset.x, y: e.clientY - mouseOffset.y });
  };

  const handleMouseUp = (e) => {
    if (isDragging) {
      setIsDragging(false);
      onRelease({ x: e.clientX - mouseOffset.x, y: e.clientY - mouseOffset.y });
    }
  };

  useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    } else {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging]);

  return (
    <div>
      {(!isDragging || (isDragging && !hideOnDrag)) && <div
        ref={originalRef}
        onMouseDown={handleMouseDown}
        style={{ display: 'inline-block' }}
      >
        {children}
      </div>}
      {isDragging && createPortal(
        <div
          ref={cloneRef}
          style={{
            zIndex: 1000,
            position: 'absolute',
            top: clonePosition.y,
            left: clonePosition.x,
            pointerEvents: 'none',
            opacity: 1,
          }}
        >
          {children}
        </div>
      ,document.body)}
    </div>
  );
};

export default DraggableClone;
